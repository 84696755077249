/*
Immersives Erzählen
*/

import * as React from "react";
import { graphql } from "gatsby";
import { useBreakpoint } from "gatsby-plugin-breakpoints";
import { useTranslation, useI18next } from "gatsby-plugin-react-i18next";

//Components
import Layout from "../../components/layout";
import {
  abschnitt as Abschnitt,
  trenner as Trenner,
} from "../../components/layoutComponents";
import Header from "../../components/header/header";
import ProjectHeader from "../../components/projects/ProjectHeader";
import ContentBlock1S from "../../components/projects/ContentBlock1S";
import ContentBlock2S from "../../components/projects/ContentBlock2S";
import ProjectFooter from "../../components/projects/ProjectFooter";
import Reihe from "../../components/generics/reihe";
import SpalteLinks from "../../components/generics/spalte_links";
import SpalteRechts from "../../components/generics/spalte_rechts";

import FlyingContactClick from "../../components/contactFlyingButton_click.js";
import SEO from "../../components/generics/seo";
import HR from "../../components/generics/HR";

//CSS
import "../../components/contactFlyingButton.css";
import "../../components/teaser/teaser.css";


//Images
import {
  ie_header, 
  ie_sheffrey, ie_forensic, ie_marshmallow, 
  ie_einfuehrung, ie_einfuehrung2, ie_einfuehrung3, ie_einfuehrung4,
  ie_stahlbock, ie_stahlbock2, ie_stahlbock3, ie_stahlbock4,
  ie_babka, ie_babka2, ie_babka3, 
  ie_hauffe, ie_hauffe2, ie_hauffe3, 
  ie_ende,ie_ende2, ie_ende3, 
  ie_fahrenkamp, ie_fahrenkamp2, ie_fahrenkamp3, 
  ie_stahlbock21, ie_stahlbock22, ie_stahlbock23, 
  ie_schede, ie_schede2, ie_schede3, ie_schede4, 
  ie_rempel, ie_rempel2, ie_rempel3, ie_rempel4, ie_rempel5
} from "../../ressources/ImmersivesErzaehlen";

const ImmersivesErzaehlen = (location) => {
  const breakpoints = useBreakpoint();
  const { t } = useTranslation("immersiveserzaehlen");
  const { language } = useI18next();

  return (
    <>
      {/* Schreibt SEO Optimierungen für die Seite (Titel, Canonical-Link, Beschreibung */}
      {/* LD/JSON bei Bedarf: Product oder ContactPoint von schema.org */}
      <SEO
        lang={language}
        title={t("meta title")}
        canonical={t("meta canonical")}
        description={t("meta description")}
        productLDJSON={true}
      />

      <Layout>
        <Header location={location} />
        {breakpoints.md ? <></> : <Trenner verysmall={true} />}

        <Abschnitt>
          {/* Direkt die übersetzten Texte nutzen */}
          {/* Für HTML Inhalte des Langtextes html={} nutzen, ohne Formatierungen das text={} Attribut */}
          {/* Für ein Video muss videoHeader={true} sein, dann die nötigen Attribute*/}
          {/* Für ein Image muss imageHeader={true} sein, dann die nötigen Attribute*/}
          <ProjectHeader
            titel={t("product titel")}
            untertitel={t("product caption")}
            imageHeader={true}
            imageTeaser={ie_header}
            hashtags={[
              { text: "AR" },
              { text: "HMI" },
              { text: "interior" },
              { text: "exterior" },
              { text: "metaverse" },
              { text: "multiplatform" },
              { text: "movingplatform" },
              { text: "hololens" },
              { text: "digitaltwin" },
              { text: "case" },
            ]}
            html={t("product description")}
          />
        </Abschnitt>

        {/* Trenner für Abstande oben/unten */}
        <Trenner small={true} />
        {/* HR = Horizontal line, full width */}
        <HR />
        <Trenner small={true} />

        <Abschnitt>
           {/* Künstlerischer Input */}  
          <ContentBlock2S
            image1={ie_sheffrey}
            unterschrift1={t("unterschrift eins 1")}
            image2={ie_forensic}
            unterschrift2={t("unterschrift eins 2")}/>
          <ContentBlock1S
            image={ie_marshmallow}
            unterschrift={t("unterschrift eins 3")}
            headline={t("ueberschrift eins")}
            html={t("info eins")}/>
          <Trenner small={true} />

          {/* Einführung Entwicklung */}  
          <ContentBlock1S
            image={ie_einfuehrung}
            unterschrift={t("unterschrift zwei")}
            headline={t("ueberschrift zwei")}
            html={t("info zwei")}/>
          <Trenner small={true} />

          {/* Einführung Photogrammetrie */}  
          <ContentBlock1S
            image={ie_einfuehrung2}
            unterschrift={t("unterschrift drei")}
            headline={t("ueberschrift drei")}
            html={t("info drei")}/>
          <Trenner small={true} />

         {/* Einführung Tilt Brush */}  
          <ContentBlock1S
            image={ie_einfuehrung3}
            unterschrift={t("unterschrift vier 1")}/>    

          <ContentBlock1S
            image={ie_einfuehrung4}
            unterschrift={t("unterschrift vier 2")}
            headline={t("ueberschrift vier")}
            html={t("info vier")}/>
          <Trenner small={true} />
        </Abschnitt>

        <Abschnitt line={true} headline={t("ergebnisse headline")}>
          
          {/* Georg Stahlbock */}  
          <ContentBlock1S
            headline={t("stahlbock")}
            html={t("info stahlbock")}/>    
          <Trenner verysmall={true} />
          <ContentBlock2S image1={ie_stahlbock} image2={ie_stahlbock2} />
          <ContentBlock1S image={ie_stahlbock3} />
          <ContentBlock1S image={ie_stahlbock4} />
          <Trenner small={true} />

           {/* David Babka */}  
           <ContentBlock1S
            headline={t("babka")}
            html={t("info babka")}/>    
          <Trenner verysmall={true} />
          <ContentBlock2S image1={ie_babka} image2={ie_babka2}/>
          <ContentBlock1S image={ie_babka3} />
          <Trenner small={true} />
          
           {/*Melanie Hauffe*/}  
           <ContentBlock1S
            headline={t("hauffe")}
            html={t("info hauffe")}/>    
          <Trenner verysmall={true} />
          <ContentBlock1S image={ie_hauffe} />
          <ContentBlock1S image={ie_hauffe2} />
          <ContentBlock1S image={ie_hauffe3} />
          <Trenner small={true} />

          {/*Pia vom Ende*/}  
          <ContentBlock1S
            headline={t("ende")}
            html={t("info ende")}/>    
          <Trenner verysmall={true} />
          <ContentBlock2S image1={ie_ende} image2={ie_ende3}/>
          <ContentBlock1S image={ie_ende2} />
          <Trenner small={true} />
          
          {/*Jannis Fahrenkamp*/}  
          <ContentBlock1S
            headline={t("fahrenkamp")}
            html={t("info fahrenkamp")}/>    
          <Trenner verysmall={true} />
          <ContentBlock2S image1={ie_fahrenkamp} image2={ie_fahrenkamp2}/>
          <ContentBlock1S image={ie_fahrenkamp3} />
          <Trenner small={true} />

          {/*Georg Stahlbock 2*/}  
          <ContentBlock1S
            headline={t("stahlbock2")}
            html={t("info stahlbock2")}/>    
          <Trenner verysmall={true} />
          <ContentBlock2S image1={ie_stahlbock21} image2={ie_stahlbock22}/>
          <ContentBlock1S image={ie_stahlbock23} />
          <Trenner small={true} />

         {/*Leon Meschede*/}  
         <ContentBlock1S
            headline={t("meschede")}
            html={t("info meschede")}/>    
          <Trenner verysmall={true} />
          <ContentBlock2S image1={ie_schede}  image2={ie_schede2}/>
          <ContentBlock2S image1={ie_schede3} image2={ie_schede4}/>
          <Trenner small={true} />

        {/*Katja Rempel*/}  
         <ContentBlock1S
            headline={t("rempel")}
            html={t("info rempel")}/>    
          <Trenner verysmall={true} />
          <ContentBlock2S image1={ie_rempel}  image2={ie_rempel5}/>
          <ContentBlock1S image={ie_rempel2}/>
          <ContentBlock2S image1={ie_rempel3} image2={ie_rempel4}/>
          <Trenner small={true} />

        </Abschnitt>    

        <Abschnitt line={true} headline={t("projektrahmen headline")}>
          {/* ProjectFooter für weitere Infos zum Projekt. text und html werden als erstes angezeigt, dann alle children */}
          {/* Mit <Reihe> und <SpalteLinks> (rechts) wird die ursprüngliche Aufteilung imitiert */}
          <ProjectFooter text={undefined} html={undefined}>
            <Reihe>
              <SpalteLinks headline={t("entstehung")} />
              <SpalteRechts content={t("entstehung_content")} />
            </Reihe>

            <Reihe>
              <SpalteLinks headline={t("kooperation")} />
              <SpalteRechts html={t("kooperation_content")} />
            </Reihe>
          </ProjectFooter>
        </Abschnitt>

        <FlyingContactClick />
      </Layout>
    </>
  );
};

export default ImmersivesErzaehlen;

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
